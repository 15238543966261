import React, { useEffect, useState } from "react"
import gql from "graphql-tag"
import { useLazyQuery } from "@apollo/react-hooks"
import { Helmet } from "react-helmet"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import { navigate } from "gatsby"
import { useMediaPredicate } from "react-media-hook"
import Select from "react-select"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { Section, Container } from "../../components/grid/grid"
import bgPage from "../../assets/img/lucky-dip/background-form-2.jpeg"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_PHONENUMBER = gql`
  query formLuckyDip {
    formLuckyDip {
      project
      phoneNumber
    }
  }
`

const LuckyDipPage = (location) =>{
  const [getResult, { data }] = useLazyQuery(GET_PHONENUMBER)
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const ogUrl = `${process.env.BASE_URL}${location.path ? location.path : ''}`

  let listOfProduct = [
    { label: 'Aerium - Jakarta' , value: 'Aerium - Jakarta'},
    { label: 'Albera - BSD' , value: 'Albera - BSD'},
    { label: 'Alegria Park - BSD' , value: 'Alegria Park - BSD'},
    { label: 'Alexandria - Surabaya' , value: 'Alexandria - Surabaya'},
    { label: 'Alfiore - Banjar Wijaya' , value: 'Alfiore - Banjar Wijaya'},
    { label: 'AMARINE - BSD' , value: 'AMARINE - BSD'},
    { label: 'Amata - BSD' , value: 'Amata - BSD'},
    { label: 'Anila House - BSD' , value: 'Anila House - BSD'},
    { label: 'Assana House - BSD' , value: 'Assana House - BSD'},
    { label: 'Atherton - Cibubur' , value: 'Atherton - Cibubur'},
    { label: 'Autoparts - BSD' , value: 'Autoparts - BSD'},
    { label: 'Azura House - BSD' , value: 'Azura House - BSD'},
    { label: 'Blossom Ville Ex - BSD' , value: 'Blossom Ville Ex - BSD'},
    { label: 'Casa De Parco - BSD' , value: 'Casa De Parco - BSD'},
    { label: 'Cattleya - Banjar Wijaya' , value: 'Cattleya - Banjar Wijaya'},
    { label: 'Cheville - Balikpapan' , value: 'Cheville - Balikpapan'},
    { label: 'Cloverville Kavling - Surabaya' , value: 'Cloverville Kavling - Surabaya'},
    { label: 'Cluster Alesha - BSD' , value: 'Cluster Alesha - BSD'},
    { label: 'Cluster Asatti - BSD' , value: 'Cluster Asatti - BSD'},
    { label: 'Cluster Askara Nue - BSD' , value: 'Cluster Askara Nue - BSD'},
    { label: 'Cluster Assana - BSD' , value: 'Cluster Assana - BSD'},
    { label: 'Cluster Azura Starlight - BSD' , value: 'Cluster Azura Starlight - BSD'},
    { label: 'Cluster Caelus - BSD' , value: 'Cluster Caelus - BSD'},
    { label: 'Cluster Whelford - BSD' , value: 'Cluster Whelford - BSD'},
    { label: 'Cluster Whitsand II  - BSD' , value: 'Cluster Whitsand II  - BSD'},
    { label: 'Da Vinci - Cibubur' , value: 'Da Vinci - Cibubur'},
    { label: 'DE MAJA - BSD' , value: 'DE MAJA - BSD'},
    { label: 'FLATHOUSE - BSD' , value: 'FLATHOUSE - BSD'},
    { label: 'Freja Suites - BSD' , value: 'Freja Suites - BSD'},
    { label: 'Garden Suite - Surabaya' , value: 'Garden Suite - Surabaya'},
    { label: 'Giri Loka - BSD' , value: 'Giri Loka - BSD'},
    { label: 'Grand Freja - BSD' , value: 'Grand Freja - BSD'},
    { label: 'Grand Palais - Surabaya' , value: 'Grand Palais - Surabaya'},
    { label: 'Hayfield - Balikpapan' , value: 'Hayfield - Balikpapan'},
    { label: 'HYLANDS - BSD' , value: 'HYLANDS - BSD'},
    { label: 'IMAJIHAUS - BSD' , value: 'IMAJIHAUS - BSD'},
    { label: 'Ingenia - BSD' , value: 'Ingenia - BSD'},
    { label: 'Kavling Phase 1 - Batam' , value: 'Kavling Phase 1 - Batam'},
    { label: 'Kavling Phase 2 - Batam' , value: 'Kavling Phase 2 - Batam'},
    { label: 'Kios Pasar Modern - Banjar Wijaya' , value: 'Kios Pasar Modern - Banjar Wijaya'},
    { label: 'Kios Pasar Modern 2/ Barat - BSD' , value: 'Kios Pasar Modern 2/ Barat - BSD'},
    { label: 'Landed Phase 1 (Blok ABCD) - Batam' , value: 'Landed Phase 1 (Blok ABCD) - Batam'},
    { label: 'Landed Phase 2 (Blok EFGH) - Batam' , value: 'Landed Phase 2 (Blok EFGH) - Batam'},
    { label: 'LUXMORE - BSD' , value: 'LUXMORE - BSD'},
    { label: 'Mississippi - Cibubur' , value: 'Mississippi - Cibubur'},
    { label: 'New Palladium - Balikpapan' , value: 'New Palladium - Balikpapan'},
    { label: 'Nordville - Balikpapan' , value: 'Nordville - Balikpapan'},
    { label: 'Northridge - BSD' , value: 'Northridge - BSD'},
    { label: 'Nusa Loka - BSD' , value: 'Nusa Loka - BSD'},
    { label: 'Palais de Luxembourg - Surabaya' , value: 'Palais de Luxembourg - Surabaya'},
    { label: 'Plot A - Banjar Wijaya' , value: 'Plot A - Banjar Wijaya'},
    { label: 'Precia - BSD' , value: 'Precia - BSD'},
    { label: 'Roma Ext - Surabaya' , value: 'Roma Ext - Surabaya'},
    { label: 'Royal Blossom - BSD' , value: 'Royal Blossom - BSD'},
    { label: 'Ruko A6 - Balikpapan' , value: 'Ruko A6 - Balikpapan'},
    { label: 'Ruko ICE Business Park - BSD' , value: 'Ruko ICE Business Park - BSD'},
    { label: 'Ruko Icon 5 - BSD' , value: 'Ruko Icon 5 - BSD'},
    { label: 'Ruko Madrid X - BSD' , value: 'Ruko Madrid X - BSD'},
    { label: 'Ruko Pro. Suites - BSD' , value: 'Ruko Pro. Suites - BSD'},
    { label: 'Ruko The Savia - BSD' , value: 'Ruko The Savia - BSD'},
    { label: 'Rumah Zena - BSD' , value: 'Rumah Zena - BSD'},
    { label: 'Southgate Altuerra - Jakarta' , value: 'Southgate Altuerra - Jakarta'},
    { label: 'Southgate Elegance - Jakarta' , value: 'Southgate Elegance - Jakarta'},
    { label: 'Southgate Prime - Jakarta' , value: 'Southgate Prime - Jakarta'},
    { label: 'Street of London - Surabaya' , value: 'Street of London - Surabaya'},
    { label: 'Street of Paris - Surabaya' , value: 'Street of Paris - Surabaya'},
    { label: 'Taman Tekno X - BSD' , value: 'Taman Tekno X - BSD'},
    { label: 'Tanakayu Jiva - BSD' , value: 'Tanakayu Jiva - BSD'},
    { label: 'Tanakayu Svadhi - BSD' , value: 'Tanakayu Svadhi - BSD'},
    { label: 'Tanakayu Svani - BSD' , value: 'Tanakayu Svani - BSD'},
    { label: 'Tanakayu Svasti - BSD' , value: 'Tanakayu Svasti - BSD'},
    { label: 'The Blizfield - BSD' , value: 'The Blizfield - BSD'},
    { label: 'The Elements - Jakarta' , value: 'The Elements - Jakarta'},
    { label: 'The Loop - BSD' , value: 'The Loop - BSD'},
    { label: 'The Savia Thp 1 - BSD' , value: 'The Savia Thp 1 - BSD'},
    { label: 'Tower Azure - Surabaya' , value: 'Tower Azure - Surabaya'},
    { label: 'Tower Kaina - Batam' , value: 'Tower Kaina - Batam'},
    { label: 'Tower Kalani - Batam' , value: 'Tower Kalani - Batam'},
    { label: 'Visana at The Savia - BSD' , value: 'Visana at The Savia - BSD'},
    { label: 'VIVACIA - BSD' , value: 'VIVACIA - BSD'},
  ]

  const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
    const phoneNumberHandler = (val, setFieldValue) =>
      setFieldValue("phoneNumber", val)
    const [checked, setChecked] = useState(false)
  
    function handleOnChange(){
      setChecked(!checked)
    }
  
    return (
      <Form className="row pt-4" id="surpriseForm">
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nama</label>
          <Field
            name="name"
            className="form-control px-0"
            placeholder="Nama Anda"
            type="text"
            id="customerName"
          />
          {touched.name && errors.name && (
            <div className="invalid-value d-block">{errors.name}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nomor Handphone</label>
          <NumberFormat
            name="phoneNumber"
            className="form-control px-0"
            placeholder="Nomor Handphone"
            allowEmptyFormatting
            prefix="+62"
            value={values.phoneNumber}
            onValueChange={(val) =>
              phoneNumberHandler(val.formattedValue, setFieldValue)
            }
            id="customerPhone"
          />
          {touched.phoneNumber && errors.phoneNumber && (
            <div className="invalid-value d-block">{errors.phoneNumber}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Email</label>
          <Field
            name="email"
            className="form-control px-0"
            placeholder="Email Anda"
            type="text"
            id="customerEmail"
          />
          {touched.email && errors.email && (
            <div className="invalid-value d-block">{errors.email}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Produk Yang Dibeli</label>
          <Select
            className="form-control px-0"
            classNamePrefix="Produk Yang Dibeli"
            options={listOfProduct}
            defaultValue={{ value: "", label: "Produk Yang Dibeli" }}
            id="product"
            onChange={(e) => {
              setFieldValue("product", e.value)
              sessionStorage.setItem("product", e.value)
            }}
          />
          {touched.product && errors.product && (
            <div className="invalid-value d-block">{errors.product}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nama Sales</label>
          <Field
            name="salesName"
            className="form-control px-0"
            placeholder="Nama sales"
            type="text"
            id="salesName"
          />
          {touched.salesName && errors.salesName && (
            <div className="invalid-value d-block">{errors.salesName}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">
            <Field 
              type="checkbox" 
              name="checked" 
              value="Agent" 
              onClick={handleOnChange}
            />
            &nbsp;Agent
          </label>
        </div>
        <div className={checked ? "col-12 col-md-12 pb-3 d-block" : "col-12 col-md-12 pb-3 d-none"}>
          <label className="text-muted my-0">Nama Kantor</label>
          <Field
            name="officeName"
            className="form-control px-0"
            placeholder="Nama Kantor"
            type="text"
            id="officeName"
          />
          {touched.officeName && errors.officeName && (
            <div className="invalid-value d-block">{errors.officeName}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3 pt-3" id="disclaimer">
          <label className="text-muted my-0">
            <Field 
              type="checkbox" 
              name="isAgree" 
              value="isAgree" 
              id="isAgree"
            />
            &nbsp;Saya menyetujui bahwa hasil dari Lucky Dip Infinite Living Periode III ini tidak dapat diganggu gugat
          </label>
          {touched.isAgree && errors.isAgree && (
            <div className="invalid-value d-block">{errors.isAgree}</div>
          )}
        </div>
        <div className="col-12 pt-5">
          <button className="btn btn-primary w-100 w-md-25">
            {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
          </button>
        </div>
      </Form>
    )
  }
  
  useEffect(() => {
    if (location.uri == "/luckydip") {
      let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
      
      if(resizeScreen){
        document.getElementsByClassName("logo-secondary")[1].style.display = `none`
        document.getElementsByClassName("logo-main")[1].style.display = `block`
      }else{
        document.getElementsByClassName("logo-secondary")[0].style.display = `none`
        document.getElementsByClassName("logo-main")[0].style.display = `block`
      }

      for (let i = 0; i < navbarDesktop.length; i++) {
        navbarDesktop[i].style.color = "#000"
      }
      document.querySelector("#lang-wrapper").classList.remove('d-flex')
      document.querySelector("#lang-wrapper").classList.add('d-none')
    }
  }, [])

  useEffect(() => {
    getResult()
  }, [])

  const [formValues, setFormValues] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    product: "",
    salesName: "",
    isAgree: "",
    officeName: "",
  })

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({ name, phoneNumber, email, product, salesName, isAgree, officeName }) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
        product: formValues.product || product,
        salesName: formValues.salesName || salesName,
        isAgree: formValues.isAgree || isAgree,
        officeName: formValues.officeName || officeName,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      product: Yup.string().required("Produk Yang Dibeli is required"),
      salesName: Yup.string().required("Nama sales is required"),
      isAgree: Yup.string().required("Disclaimer is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)

      let dataList = data.formLuckyDip

      for (let i = 0; i < dataList.length; i++) {
        if (dataList[i].phoneNumber == values.phoneNumber.split("+").join("")) {
          alert("Maaf! Anda tidak bisa mengikuti program Lucky Dip kembali")
          return false
        }
      }

      let form = document.getElementById("surpriseForm")
      for (let q = 0; q < form.elements.length; q++) {
        let eSecond = form.elements[q]
        if (eSecond.type === "button") continue
        let idSecond = eSecond.id
        var valueSecond = eSecond.value
        sessionStorage.setItem(idSecond, valueSecond)
      }
      navigate("/luckydip/pop")

    },
  })(LeadForm)

  return(
    <Layout location={location} currentLocation={location.uri}>
      <SEO 
        title={`Lucky Dip Infinite Living Periode III, Dapatkan Tambahan Promonya`}
        fbTitle={`Lucky Dip Infinite Living Periode III, Dapatkan Tambahan Promonya`}
        twitterTitle={`Lucky Dip Infinite Living Periode III, Dapatkan Tambahan Promonya`}
        description={`Special Promo Sinar Mas Land! Mainkan Lucky Dip Infinite Living Periode III 2024 dan menangkan tambahan promo special dari Sinar Mas Land`}
        fbDescription={`Special Promo Sinar Mas Land! Mainkan Lucky Dip Infinite Living Periode III 2024 dan menangkan tambahan promo special dari Sinar Mas Land`}
        twitterDescription={`Special Promo Sinar Mas Land! Mainkan Lucky Dip Infinite Living Periode III 2024 dan menangkan tambahan promo special dari Sinar Mas Land`}
        url={ogUrl}
        img={`https://www.sinarmasland.com/app/uploads/2024/09/Infinite-Living-Periode-III.jpeg`}
        fbImg={`https://www.sinarmasland.com/app/uploads/2024/09/Infinite-Living-Periode-III.jpeg`}
        twitterImg={`https://www.sinarmasland.com/app/uploads/2024/09/Infinite-Living-Periode-III.jpeg`}
      />
      <Helmet>
        <meta 
          name="Permissions-Policy" 
          content="autoplay=(self)"
        ></meta>
        <body className="bd-page" />
        <meta 
          name="keywords" 
          content="Lucky Dip 2024, diskon properti sinar mas land, program lucky dip, "
        ></meta>
        <meta 
          name="copyright" 
          content="2023 | Sinar Mas Land"
        ></meta>
      </Helmet>
      <Section 
        className="mainSurpriseKetupat bg-lucky-dip-form bg-gray-50" 
        id="extraKetupatSection" 
        style={{
          backgroundImage: `url(${bgPage})`,
        }}>
        <Container className="surpriseForm">
          <h1 className="text-center" style={{
            color: "#1e333e"
          }}>
            Lucky Dip Infinite Living Periode III
          </h1>
          <EnhancedLeadForm />
        </Container>
      </Section>
    </Layout>
  )
}
export default LuckyDipPage
